<template>
  <div id="details-boleto-modal">
    <vs-popup class="popup90" :title="title" :active.sync="localShow">

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="flex items-start flex-col lg:flex-row">
            <div class="w-full">
              <div class="vx-row">
                <div class="vx-col md:w-1/4 w-full mt-0">
                  <label class="vs-input--label">Data de processamento</label> <br>
                  <span>{{ boleto.data_processamento | dateTime('dateTime', 'h') }}</span>
                </div>
                <div class="vx-col md:w-1/4 w-full mt-0">
                  <label class="vs-input--label">Data do Documento</label> <br>
                  <span>{{boleto.data_documento | dateTime('dateTime', 'h')}}</span>
                </div>
                <div class="vx-col md:w-1/4 w-full mt-0">
                  <label class="vs-input--label">Data de Vencimento</label> <br>
                  <span>{{boleto.data_vencimento | dateTime('dateTime', 'h')}}</span>
                </div>
                <div class="vx-col md:w-1/4 w-full mt-0">
                  <label class="vs-input--label">Data do Crédito</label> <br>
                  <span>{{ boleto.data_credito ? `${boleto.data_credito | dateTime('dateTime', 'h')}` : 'Em Aberto'}}</span>
                </div>
              </div>

              <div class="vx-row">
                <div class="vx-col md:w-1/4 w-full mt-0">
                  <label class="vs-input--label">Valor</label> <br>
                  <span> {{ boleto.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }} </span>
                </div>
                <div class="vx-col md:w-1/4 w-full mt-0">
                  <label class="vs-input--label">Valor de juros</label> <br>
                  <span>{{ boleto.type_interest == '%' ? boleto.interest + ' '+ boleto.type_interest : boleto.interest && boleto.type_interest + ' '+ boleto.interest.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                </div>
                <div class="vx-col md:w-1/4 w-full mt-0">
                  <label class="vs-input--label">Valor de multa</label> <br>
                  <span>{{ boleto.type_fee == '%' ? boleto.fee + ' '+ boleto.type_fee : boleto.fee && boleto.type_fee + ' '+ boleto.fee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                </div>
                <div class="vx-col md:w-1/4 w-full mt-0">
                  <label class="vs-input--label">Nosso Nº</label> <br>
                  <span>{{ boleto.nosso_numero }}</span>
                </div>
              </div>

              <div class="flex items-end mt-2">
                <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
                <span class="leading-none font-medium">Pagador</span>
              </div>
              <hr>

              <div class="vx-row">
                <div class="vx-col md:w-1/4 w-full mt-2">
                  <label class="vs-input--label">Nome</label> <br>
                  <span>{{ boleto.transaction_cfc && boleto.transaction_cfc.student.name }}</span>
                </div>
                <div class="vx-col md:w-1/4 w-full mt-2">
                  <label class="vs-input--label">CPF</label> <br>
                  <span>{{ boleto.transaction_cfc && boleto.transaction_cfc.student.cpf }}</span>
                </div>
                <div class="vx-col md:w-1/4 w-full mt-2">
                  <label class="vs-input--label">Acesso aos dados do pagador</label> <br>
                  <router-link v-if="boleto.transaction_cfc" :to="`/student/student-edit/${boleto.transaction_cfc.student.id}`" @click.stop.prevent class="text-inherit hover:text-primary">Clique aqui.</router-link>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-button class="ml-4 mt-2" type="border" color="secondary" @click="cancel">Fechar</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect
  },
  props: {
    show: {
      Boolean, default: false
    },
    title: {
      String, default: 'Diálogo'
    },
    actionButtonText: {
      String, default: 'Deletar'
    },
    actionButtonColor: {
      String, default: 'danger'
    }
  },

  computed: {
    validateForm () {
      return !this.errors.any()
    }
  },

  data () {
    return {
      localShow: false,
      boleto: {
        data_processamento: null,
        data_documento: null,
        data_vencimento: null,
        data_credito: null,
        type_interest: '',
        interest: 0,
        type_fee: 0,
        fee: 0,
        valor: 0
      }
    }
  },

  watch: {
    show (val) {
      this.localShow = val
    },
    localShow (val) {
      if (!val) {
        this.boleto = {
          data_processamento: null,
          data_documento: null,
          data_vencimento: null,
          data_credito: null,
          type_interest: '',
          interest: 0,
          type_fee: 0,
          fee: 0,
          valor: 0
        }
        this.$emit('cancel')
      } else {
        this.boleto = JSON.parse(JSON.stringify(this.$store.state.boleto.boleto))
      }
    }
  },

  methods: {
    action () {
      this.$emit('action')
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style>
  hr {
    margin-top: 2px;
    border: none;
    height: 1px;
    /* Set the hr color */
    color: rgb(204, 204, 204); /* old IE */
    background-color: rgb(204, 204, 204); /* Modern Browsers */
  }

  .popup90 .vs-popup {
    width: 90% !important;
  }

  .vue_select_drop_size_200 .vs__dropdown-menu {
    max-height: 200px;
  }
</style>
