var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "details-boleto-modal" } },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: { title: _vm.title, active: _vm.localShow },
          on: {
            "update:active": function ($event) {
              _vm.localShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "flex items-start flex-col lg:flex-row" },
                [
                  _c("div", { staticClass: "w-full" }, [
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v("Data de processamento"),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateTime")(
                                  _vm.boleto.data_processamento,
                                  "dateTime",
                                  "h"
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v("Data do Documento"),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateTime")(
                                  _vm.boleto.data_documento,
                                  "dateTime",
                                  "h"
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v("Data de Vencimento"),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateTime")(
                                  _vm.boleto.data_vencimento,
                                  "dateTime",
                                  "h"
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v("Data do Crédito"),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.boleto.data_credito
                                  ? `${
                                      _vm.boleto.data_credito |
                                      _vm.dateTime("dateTime", "h")
                                    }`
                                  : "Em Aberto"
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v("Valor"),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.boleto.valor.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  })
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v("Valor de juros"),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.boleto.type_interest == "%"
                                  ? _vm.boleto.interest +
                                      " " +
                                      _vm.boleto.type_interest
                                  : _vm.boleto.interest &&
                                      _vm.boleto.type_interest +
                                        " " +
                                        _vm.boleto.interest.toLocaleString(
                                          "pt-BR",
                                          { style: "currency", currency: "BRL" }
                                        )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v("Valor de multa"),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.boleto.type_fee == "%"
                                  ? _vm.boleto.fee + " " + _vm.boleto.type_fee
                                  : _vm.boleto.fee &&
                                      _vm.boleto.type_fee +
                                        " " +
                                        _vm.boleto.fee.toLocaleString("pt-BR", {
                                          style: "currency",
                                          currency: "BRL",
                                        })
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v("Nosso Nº"),
                          ]),
                          _c("br"),
                          _c("span", [_vm._v(_vm._s(_vm.boleto.nosso_numero))]),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex items-end mt-2" },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-2",
                          attrs: { icon: "UserIcon", svgClasses: "w-5 h-5" },
                        }),
                        _c(
                          "span",
                          { staticClass: "leading-none font-medium" },
                          [_vm._v("Pagador")]
                        ),
                      ],
                      1
                    ),
                    _c("hr"),
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v("Nome"),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.boleto.transaction_cfc &&
                                  _vm.boleto.transaction_cfc.student.name
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v("CPF"),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.boleto.transaction_cfc &&
                                  _vm.boleto.transaction_cfc.student.cpf
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v("Acesso aos dados do pagador"),
                          ]),
                          _c("br"),
                          _vm.boleto.transaction_cfc
                            ? _c(
                                "router-link",
                                {
                                  staticClass:
                                    "text-inherit hover:text-primary",
                                  attrs: {
                                    to: `/student/student-edit/${_vm.boleto.transaction_cfc.student.id}`,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [_vm._v("Clique aqui.")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4 mt-2",
                      attrs: { type: "border", color: "secondary" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("Fechar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }